<template>
    <div>
        <a-spin :spinning="loading">
            <div class="layout-header-true">
                <div class="flex alcenter space" style="height: 100%;overflow: hidden;">
                    <div class="flex alcenter" style="overflow: hidden auto;">
                        <img v-if="install_site.user_backend_applications_url" :src="BASE_IMG_URL+install_site.user_backend_applications_url" class="layout-header-logo" />
                        <div class="ml60">
                            <div class="flex alcenter wrap menu-list">
                                <div class="menu-list-item" v-for="(item,index) in  menus" :key="index" @click="menuAct(index)" :class="{active:getSelectKey.indexOf(item.key) > -1}">
                                    <i class="iconfont  ft14" :class="item.icon" />
                                    <div class="ft14 ftw400 ml8">{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <a-dropdown placement="bottomCenter">
                            <span>
                               您好！ {{admin.username}}
                                <a-icon type="down" />
                            </span>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a class="menu-act" href="javascript:;" @click="showPwd = true">
                                        <i class="iconfont ft14 iconedit"></i>
                                        <span class="ml10">修改密码</span>
                                    </a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a class="menu-act" href="javascript:;" @click="loginOut()">
                                        <i class="iconfont ft14 iconsubnav_shop04"></i>
                                        <span class="ml10">退出登录</span>
                                    </a>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
            </div>
            <div class="layout-header-stand-in"></div>

            <div class="layout-content pd20">
                <router-view></router-view>
            </div>

            <a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
                <template slot="footer">
                    <a-button key="back" @click="handlePwdCancel">
                        取消
                    </a-button>
                    <a-button key="submit" type="primary" :loading="pwdloading" @click="handlePwdOk">
                        确认
                    </a-button>
                </template>
                <a-form-model :model="pwd" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="输入密码">
                        <a-input type="password" v-model="pwd.pwd" />
                    </a-form-model-item>
                    <a-form-model-item label="再次确认">
                        <a-input type="password" v-model="pwd.repwd" />
                    </a-form-model-item>
                </a-form-model>
            </a-modal>
        </a-spin>
    </div>
</template>

<script>
export default {
    data() {
        return {
            BASE_IMG_URL:window.global_config.BASE_IMG_URL,
            install_site:{},
            loading: false,
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            showPwd: false,
            pwd: {
                pwd: '',
                repwd: '',
            },
            pwdloading: false,
            admin: {
                name: '',
            },
            siteInfo: null,
            menus: [
                {
                    name: '首页',
                    icon: 'icontop_nav01',
                    key: 'home',
                    link: '/home'
                },
                {
                    name: '用户',
                    icon: 'iconzhanghao1',
                    key: 'account',
                    link: '/account'
                },
                {
                    name: '门店',
                    icon: 'icontop_nav02',
                    key: 'shop',
                    link: '/shop'
                },
                // {
                // 	name: '应用',
                // 	icon: 'icontop_nav06',
                // 	key: 'application',
                // 	link: '/application'
                // },
                // {
                // 	name: '模版',
                // 	icon: 'iconmoban',
                // 	key: 'template',
                // 	link: '/template'
                // },
                {
                    name: '设置',
                    icon: 'icontop_nav05',
                    key: 'setting',
                    link: '/setting'
                },
                {
                    name: '代理',
                    icon: 'iconmoban',
                    key: 'agent',
                    link: '/agent'
                },
            ],
        };
    },
    created() {
        // this.getSiteInfo();
        this.loaddata();
    },
    methods: {
        getSiteInfo() {
            this.$http.api('platform/siteInfo').then(res => {
                this.siteInfo = res.site;
            }).catch(res => {
                console.log(res);
            });
        },
        loaddata() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/user/UserAdminInfo').then(res => {
                this.admin = res.data;
                this.install_site = res.data.install_site
            }).catch(res => {
                console.log(res);
            }).finally(() => {
                this.loading = false;
            })
        },
        menuAct(index) {

            let data = this.menus[index];
            // if(data.key === 'shop'){
            // 	 this.$message.info('门店后台开发中');
            // 	 return
            // }
            if (data.key != this.getSelectKey) {
                this.$router.push(data.link);
            }
        },
        handlePwdCancel() {
            this.showPwd = false;
        },
        handlePwdOk() {
            if (this.pwd.pwd != this.pwd.repwd) {
                this.$message.success('2次密码不一样~');
                return
            }
            if (this.pwdloading == true) return;
            this.pwdloading = true;
            this.$http.api('api/user/reset', {
                password: this.pwd.pwd,
                // repwd: this.pwd.repwd
            }).then(res => {
                this.pwdloading = false;
                this.$message.success('密码设置成功');
                this.showPwd = false;
            }).catch(res => {
                this.pwdloading = false;
            });
        },
        loginOut() {
            this.$http.api('api/user/logout', {}).then(res => {
                localStorage.removeItem('admin-token');
                this.$router.push('/');
            }).catch(res => {
            });

        }
    }
};
</script>

<style>
.layout-header-stand-in {
    height: 60px;
    width: 100%;
}

.layout-header-true {
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    background: #4772ff;
    padding: 0 20px 0 20px;
    z-index: 999;
    color: #ffffff;
}

.layout-header-logo {
    height: 24px;
}

.layout-content {
    min-height: calc(100vh - 60px);
}

.menu-list-item {
    width: 92px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.menu-list-item.active {
    background: #4067e6;
    color: rgba(255, 255, 255, 1);
}
</style>
